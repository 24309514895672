.background {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  min-height: fit-content !important;
}

.flex {
  display: flex;
  flex-direction: column;
  min-height: fit-content;
}

.about-background {
  background-image: url("https://images.pexels.com/photos/3124958/pexels-photo-3124958.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
}

#about-section {
  width: 90%;
  margin: 2% auto;
  background-color: rgba(245, 242, 242, 0.6);
  padding: 2%;
  border-radius: 15px;
  overflow: scroll;
}

@media screen and (min-width: 768px) {
  #about-section {
    width: 80%;
    margin: 5% auto;
    font-size: 1.2em;
  }
}

@media screen and (min-width: 900px) {
  #about-section {
    width: 70%;
    margin: 5% auto;
    font-size: 1.3em;
    /* overflow-y: hidden; */
  }
}

@media screen and (min-width: 1200px) {
  #about-section {
    font-size: 1.4em;
  }
}
