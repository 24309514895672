.portfolio-background {
  background-image: url("https://images.unsplash.com/photo-1599070221195-bf2801877d7e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80");
  overflow-y: scroll;
}

#portfolio-header {
  color: rgb(235, 222, 222);
  border: 1.5px solid rgb(235, 222, 222);
  padding: 10px 30px;
  border-radius: 4px;
  width: fit-content;
  margin: auto;
  position: sticky;
}
.small-double-border {
  border: 2px solid rgb(235, 222, 222);
  padding: 12px 20px;
  width: fit-content;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 15px;
  border-radius: 12px;
}

.projectContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  overflow-x: scroll !important;
}

.project-item {
  background-size: cover;
  margin: 10px;
  width: 350px;
  height: 275px;
}
.proj-double {
  position: relative !important;
  margin: 15px 0;
}
.proj-drop {
  opacity: 0;
  background-color: rgb(235, 222, 222);
  position: absolute !important;
  top: 0;
  padding: 5px;
  margin: 10px;
  width: 350px;
  height: 290px;
  border-radius: 15px;
  z-index: 1;
  transition: 0.5s;
}
.proj-drop:hover {
  opacity: 1;
}

.proj-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
}

.site-btn {
  text-decoration: none;
  border: #212529 1px solid;
  width: fit-content;
  margin: 0 auto;
  border-radius: 8px;
  padding: 5px 10px;
  color: #212529;
}

.site-btn:hover {
  text-decoration: none;
  background-color: #212529;
  color: rgb(235, 222, 222);
}

@media screen and (min-width: 700px) {
  .projectContainer {
    min-width: fit-content !important;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll !important;
    margin-top: 15%;
  }
  .project-item,
  .proj-drop {
    width: 375px;
    height: 315px;
    margin: 0 50px;
  }
  #portfolio-header {
    color: rgb(235, 222, 222);
  }

  .small-double-border {
    margin-top: 5%;
    margin-bottom: 30px;
    position: absolute;
    left: 40%;
  }
}

@media screen and (min-width: 1000px) {
  .project-item,
  .proj-drop {
    width: 450px;
    height: 375px;
    margin: 20px 50px;
  }
}
