body {
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
