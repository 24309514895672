@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");

* {
  font-family: "Nunito", sans-serif;
  letter-spacing: 2.5px;
}

.home-background {
  background-image: url("https://images.pexels.com/photos/1146642/pexels-photo-1146642.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
}

main {
  width: 95%;
  margin: 0 auto;
  padding-top: 15px;
  height: 70vh;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

section {
  letter-spacing: 2px;
  text-align: center;
}

#main-page-header {
  font-size: 30px;
  opacity: 0;
  animation: fadeIn 2s;
  animation-delay: 3.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.hello-typewriter {
  animation: fadeIn 2s 4.8s forwards reverse;
}

.full-stack-typewriter {
  opacity: 0;
  animation: fadeIn 0.1s 7.1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @media screen and (min-width: 1200px) {
  #home {
    font-size: 2em;
  }
} */
