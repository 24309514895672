@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap);
* {
  font-family: "Nunito", sans-serif;
  letter-spacing: 2.5px;
}

.home-background {
  background-image: url("https://images.pexels.com/photos/1146642/pexels-photo-1146642.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
}

main {
  width: 95%;
  margin: 0 auto;
  padding-top: 15px;
  height: 70vh;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

section {
  letter-spacing: 2px;
  text-align: center;
}

#main-page-header {
  font-size: 30px;
  opacity: 0;
  animation: fadeIn 2s;
  animation-delay: 3.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.hello-typewriter {
  animation: fadeIn 2s 4.8s forwards reverse;
}

.full-stack-typewriter {
  opacity: 0;
  animation: fadeIn 0.1s 7.1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @media screen and (min-width: 1200px) {
  #home {
    font-size: 2em;
  }
} */

.portfolio-background {
  background-image: url("https://images.unsplash.com/photo-1599070221195-bf2801877d7e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80");
  overflow-y: scroll;
}

#portfolio-header {
  color: rgb(235, 222, 222);
  border: 1.5px solid rgb(235, 222, 222);
  padding: 10px 30px;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  position: -webkit-sticky;
  position: sticky;
}
.small-double-border {
  border: 2px solid rgb(235, 222, 222);
  padding: 12px 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 15px;
  border-radius: 12px;
}

.projectContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  overflow-x: scroll !important;
}

.project-item {
  background-size: cover;
  margin: 10px;
  width: 350px;
  height: 275px;
}
.proj-double {
  position: relative !important;
  margin: 15px 0;
}
.proj-drop {
  opacity: 0;
  background-color: rgb(235, 222, 222);
  position: absolute !important;
  top: 0;
  padding: 5px;
  margin: 10px;
  width: 350px;
  height: 290px;
  border-radius: 15px;
  z-index: 1;
  transition: 0.5s;
}
.proj-drop:hover {
  opacity: 1;
}

.proj-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
}

.site-btn {
  text-decoration: none;
  border: #212529 1px solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  border-radius: 8px;
  padding: 5px 10px;
  color: #212529;
}

.site-btn:hover {
  text-decoration: none;
  background-color: #212529;
  color: rgb(235, 222, 222);
}

@media screen and (min-width: 700px) {
  .projectContainer {
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll !important;
    margin-top: 15%;
  }
  .project-item,
  .proj-drop {
    width: 375px;
    height: 315px;
    margin: 0 50px;
  }
  #portfolio-header {
    color: rgb(235, 222, 222);
  }

  .small-double-border {
    margin-top: 5%;
    margin-bottom: 30px;
    position: absolute;
    left: 40%;
  }
}

@media screen and (min-width: 1000px) {
  .project-item,
  .proj-drop {
    width: 450px;
    height: 375px;
    margin: 20px 50px;
  }
}

.background {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
}

.flex {
  display: flex;
  flex-direction: column;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.about-background {
  background-image: url("https://images.pexels.com/photos/3124958/pexels-photo-3124958.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
}

#about-section {
  width: 90%;
  margin: 2% auto;
  background-color: rgba(245, 242, 242, 0.6);
  padding: 2%;
  border-radius: 15px;
  overflow: scroll;
}

@media screen and (min-width: 768px) {
  #about-section {
    width: 80%;
    margin: 5% auto;
    font-size: 1.2em;
  }
}

@media screen and (min-width: 900px) {
  #about-section {
    width: 70%;
    margin: 5% auto;
    font-size: 1.3em;
    /* overflow-y: hidden; */
  }
}

@media screen and (min-width: 1200px) {
  #about-section {
    font-size: 1.4em;
  }
}

.contact-background {
  background-image: url("https://images.unsplash.com/photo-1533134486753-c833f0ed4866?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80");
}

#contact-section {
  color: rgb(235, 222, 222);
  width: 90%;
  margin: 20% auto;
}

#contact p {
  font-weight: bold;
  font-size: 1.2em;
}

#contact-section h2 {
  border: 1px solid rgb(235, 222, 222);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 15px;
}

.double-border {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 15px;
  border: 1px solid rgb(235, 222, 222);
  border-radius: 5px;
}

.icon {
  font-size: 2.5em;
  margin: 2px 10px;
  transition: 0.5s;
}
.icon:hover {
  font-size: 2.9em;
  fill: rgb(165, 165, 187);
}
.icon-link {
  text-decoration: none;
  color: rgb(165, 165, 187);
}

@media screen and (min-width: 700px) {
  #contact-section {
    width: 70%;
    margin: 15% auto;
    font-size: 1.1em;
  }
}

@media screen and (min-width: 1200px) {
  #contact-section {
    font-size: 2em;
  }
}

body {
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

